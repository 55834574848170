<main class="login_wrapper my3" style="height: 100vh; background: aliceblue;">
    <div class="session d-flex justify-content-center">
<div class="div-center">
    <div class="div-center-cn text-center">
        <i class="fa fa-close" aria-hidden="true"></i>
    <h4 class="text-danger fw-600">{{errorMsg}}</h4>
</div>
</div>
    </div>
</main>

