import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { FailedComponent } from './failed/failed.component';
import { RPostAuthenticationComponent } from './rpost-authentication/rpost-authentication.component';
import { RpostAuthenticationLogoutComponent } from './rpost-authentication-logout/rpost-authentication-logout.component';
import { environment } from 'src/environments/environment';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SocialLoginModule, SocialAuthServiceConfig, GoogleInitOptions } from '@abacritt/angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from '@abacritt/angularx-social-login';
import { EmailAutenticationComponent } from './email-autentication/email-autentication.component';
// import ngx-translate and the http loader
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient} from '@angular/common/http';
import { ResetPasswordReminderComponent } from './reset-password-reminder/reset-password-reminder.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PasswordExpiredComponent } from './password-expired/password-expired.component';
import { ErrorpageComponent } from './errorpage/errorpage.component';
//import { ErrorInterceptor } from './_helpers/error.interceptor';
import { ReactiveFormsModule } from '@angular/forms';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { ResetPasswordSuccessComponent } from './reset-password-success/reset-password-success.component';
import { ResetPasswordErrorComponent } from './reset-password-error/reset-password-error.component';



const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal
let clientIdPv='';
let postLogoutRedirectUri='';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../../assets/i18n/', '.json');
}
export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
  
  
}

export function MSALInstanceFactory(): IPublicClientApplication {
  //clientIdPv='89278687-d54e-4321-81af-9e0d5f2f8cda';
  clientIdPv= localStorage.getItem('clienIdLS')!;
  postLogoutRedirectUri = localStorage.getItem("postLogoutRedirectUri")!;
  /* alert(clientIdPv);
  alert('app.module.ts'); */
  return new PublicClientApplication({
    
    auth: {
      // clientId: '6226576d-37e9-49eb-b201-ec1eeb0029b6', // Prod enviroment. Uncomment to use. 
      clientId: environment.azureSSOClientId, // '89278687-d54e-4321-81af-9e0d5f2f8cda', // PPE testing environment
      // authority: 'https://login.microsoftonline.com/common', // Prod environment. Uncomment to use.
      authority: 'https://login.microsoftonline.com/common', // PPE testing environment.
      redirectUri: '/',
      postLogoutRedirectUri: '/'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
      ,tokenRenewalOffsetSeconds: 300
    }
  });
}
// required for AOT compilation
// export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
//   return new TranslateHttpLoader(http);
// }
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  // protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']); // Prod environment. Uncomment to use.
  protectedResourceMap.set('https://graph.microsoft-ppe.com/v1.0/me', ['user.read']);

  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Popup,
    authRequest: {
      //scopes: ['user.read'] //microsft graph aPI
      //scopes: ["api://8f491afb-add0-4cd4-82a5-f9e477d6a057/.default"], // client ID of the API
      scopes: ["api://"+environment.scopeId+"/.default"], // client ID of the API
    },
    loginFailedRoute: '/login-failed'
  };
}
const googleLoginOptions: GoogleInitOptions = {
  oneTapEnabled: false, // default is true
  scopes: 'https://www.googleapis.com/auth/calendar.readonly'
}; // https://developers.google.com/identity/oauth2/web/guides/use-token-model#initialize_a_token_client

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProfileComponent,
    FailedComponent,
    RPostAuthenticationComponent,
    RpostAuthenticationLogoutComponent,
    HeaderComponent,
    FooterComponent,
    EmailAutenticationComponent,
    ResetPasswordReminderComponent,
    ResetPasswordComponent,
    PasswordExpiredComponent,
    ErrorpageComponent,
    PasswordResetComponent,
    ResetPasswordSuccessComponent,
    ResetPasswordErrorComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatButtonModule,
    MatToolbarModule,
    MatListModule,
    MatMenuModule,
    HttpClientModule,
    MsalModule,
    SocialLoginModule,
    // ngx-translate and the loader module
    HttpClientModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    //{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    //{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              environment.googleSSOClientId,googleLoginOptions
            )
          }
          /* ,
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('clientId')
          } */
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { 

  constructor(private route: ActivatedRoute) {
    //alert(this.route.snapshot.queryParamMap.get('clientId'));
    //this.route.params.subscribe( params => clientIdPv =(params['clientId']));
    //clientIdPv=localStorage.getItem('clienIdLS')!;
    //alert(clientIdPv);
    //alert(this.clientId);
    //localStorage.setItem("clienIdLS",this.clientId); */
   }

   ngOnInit(): void {
    /* let clientIdPv=0;
    this.route.paramMap.subscribe((params: ParamMap) => {
      clientIdPv= +params.get('clientId')!
    })
    alert(clientIdPv); */
  }
}
