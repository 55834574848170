import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonUtilService } from '../service/common-util.service';
import { ResetpasswordService } from '../service/resetpassword.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslationLoaderService } from '../translation-loader.service';
import { UserSessionService } from '../common/user-session.service';
import { FormBuilder } from '@angular/forms';
import { AppError } from '../common/class/app-error';
import { IAccessTokenResponseDetails } from '../Model/ResetPassword/AccessTokenResponseDetails';
import { AccessTokenRequestModel } from '../Model/ResetPassword/AccessTokenRequestModel';
import { ErrorReason } from '../Model/ErrorReasonModel';
import { LocationStrategy } from '@angular/common';

@Component({
  selector: 'app-reset-password-reminder',
  templateUrl: './reset-password-reminder.component.html',
  styleUrls: ['./reset-password-reminder.component.css']
})
export class ResetPasswordReminderComponent {
  numDaysToExpire:number=0;
  qs: any;
  stepper: any;

  constructor(private route: ActivatedRoute, private commonUtilSvc:CommonUtilService
    , private restPwdSvc:ResetpasswordService
    ,private translate: TranslateService
    
    ,private langSvc: TranslateService
      ,private langLoaderSvc: TranslationLoaderService
      ,private user: UserSessionService
      ,private formBuilder: FormBuilder
      ,private location: LocationStrategy){
    this.route.queryParams.subscribe(params => {
      
      this.qs = params['qs'];
      //this.numDaysToExpire=this.qs;
      this.numDaysToExpire=parseInt(this.commonUtilSvc.ConvertBase64ToString(this.qs));
     });
    
     this.disableBrowserBackButtonAngular();
      
  }
  disableBrowserBackButtonAngular()
{
  history.pushState(null, null!, window.location.href);
    // check if back or forward button is pressed.
    this.location.onPopState(() => {
        history.pushState(null, null!, window.location.href);
        this.stepper.previous();
    });
}
  async ngOnInit(): Promise<void> {
    //setTimeout("disableBack()", 0);
    //setTimeout( () => {
      this.disableBack();
    //}, 1000);
    this.commonUtilSvc.hideCommonProgressBar();
    
    this.commonUtilSvc.hideProgressBar();
  }
  continueClickEvent()
  {
    
    let mfaToken='';
    if(localStorage.getItem('MFAToken')!=null && localStorage.getItem('MFAToken')!=undefined)
    {
      mfaToken=localStorage.getItem('MFAToken')!;
    }
    
    let msgToPost_Json='';
    if(localStorage.getItem("MsgToPost_"+mfaToken)!=null && localStorage.getItem("MsgToPost_"+mfaToken)!=undefined)
    {
      msgToPost_Json=localStorage.getItem("MsgToPost_"+mfaToken)!;
    }
    let randomNumber= this.commonUtilSvc.GetRandomNumber()+'_'+mfaToken;
    let callBackUrl='';
    if(localStorage.getItem("RedirectURI_"+mfaToken)!=null && localStorage.getItem("RedirectURI_"+mfaToken)!=undefined)
    {
      callBackUrl=localStorage.getItem("RedirectURI_"+mfaToken)!;
    }
    let clientId='';
    if(localStorage.getItem("ClientId_"+ mfaToken)!=null && localStorage.getItem("ClientId_"+ mfaToken)!=undefined)
    {
        clientId=localStorage.getItem("ClientId_"+ mfaToken)!;
    }
    let clientAppLoginUrl='';
    if(localStorage.getItem("RedirectURI_"+ mfaToken)!=null && localStorage.getItem("RedirectURI_"+ mfaToken)!=undefined)
    {
       clientAppLoginUrl=localStorage.getItem("RedirectURI_"+mfaToken)!;
    }
    let isOTPVerified='false';
    if(localStorage.getItem("OTPVerified_"+mfaToken)!=null && localStorage.getItem("OTPVerified_"+mfaToken)!=undefined)
    {
      isOTPVerified=localStorage.getItem("OTPVerified_"+mfaToken)!;
    }
    if(isOTPVerified=='true') //Came from MFA->After OTP verification
      {
        console.log("OTP verified");
        
        
      } 
    else //MFA not enabled, get accesstoken from API
    {
      console.log("MFA not enabled, PasswordChange is 1(Suggested Password change)")

    }
      let sourceClientId='';
      if(localStorage.getItem("SourceClientId_"+ mfaToken)!=null && localStorage.getItem("SourceClientId_"+ mfaToken)!=undefined)
      {
        sourceClientId=localStorage.getItem("SourceClientId_"+ mfaToken)!
      }
      let loginUrl=localStorage.getItem("LoginUrl_"+mfaToken);
      if(localStorage.getItem("LoginUrl_"+ mfaToken)!=null && localStorage.getItem("LoginUrl_"+ mfaToken)!=undefined)
      {
        loginUrl=localStorage.getItem("LoginUrl_"+mfaToken)!;
      }
      this.commonUtilSvc.showProgressBar();
      var obj = {
        mfA_Token:mfaToken,
        client_Id:clientId,
        sourceClient_Id:sourceClientId,
        redirectURI: clientAppLoginUrl
        
      }
      this.restPwdSvc.GetAccessToken(obj)
      .subscribe({
        next: (resp: unknown) => {
          console.log(resp);
          
          this.commonUtilSvc.hideProgressBar();
              if (!(resp instanceof AppError)) {      
      
          const response = resp as unknown as IAccessTokenResponseDetails;
          const result =  response.resultContent;
          if(response==null)
          {
              let errorMsg=ErrorReason.RESET_PWD_SCREEN_RESPONSE_IS_EMPTY;
              this.commonUtilSvc.displayValidationErroMsg(errorMsg);
              
          }
          else
          {
            let trustDevice='false';
            if(response.status=='Success' && response.statusCode==200 && response.message!=null 
            && response.message.messageCode=='RCAP-1006')
            {
              if(localStorage.getItem("trustDeviceChecked_"+mfaToken)!=null 
                  && localStorage.getItem("trustDeviceChecked_"+mfaToken) !=undefined)
              {
                trustDevice = localStorage.getItem("trustDeviceChecked_"+mfaToken)!;
              }

              let trustDeviceExpirationDate='';
              if(localStorage.getItem("TrustDeviceExpirationDate_"+mfaToken)!=null 
                && localStorage.getItem("TrustDeviceExpirationDate_"+mfaToken)!=undefined
                && localStorage.getItem("TrustDeviceExpirationDate_"+mfaToken)!=''
                )
                {
                  trustDeviceExpirationDate= localStorage.getItem("TrustDeviceExpirationDate_"+mfaToken)!;

                }
              
              let randomNumber=this.commonUtilSvc.GetRandomNumber()+'_'+obj.mfA_Token;
              let callbackUrl=obj.redirectURI;
              let clientId=obj.client_Id;
              let clientAppLoginUrl=loginUrl!;
              
             //post message to client
             let msgToPost_Json=this.commonUtilSvc.prepareMsgToPost_Json(obj.mfA_Token,trustDevice,result.access_Token,trustDeviceExpirationDate,"RESET_Password_Reminder");
            
             this.commonUtilSvc.postMessageToClient(msgToPost_Json,"iframe_"+randomNumber,callbackUrl,clientId,clientAppLoginUrl);
              
            }
            else{
              let errorMsg=this.translate.instant('ResetPassword.ResetPasswordErrorValidationMsg');
              this.commonUtilSvc.displayValidationErroMsg(errorMsg);
            }
            

          }
          
        }
        else
        {
          const response = resp as AppError;
          let errorMsg=response.Message!;
          let sessionExpiryMsg=ErrorReason.RESET_PWD_SESSION_EXPIRED_MSG;
          if(errorMsg == 'MFA token is invalid'|| errorMsg.indexOf('MFA token is invalid')!=-1)
          {
            errorMsg=sessionExpiryMsg;
          }
          setTimeout(function () {
                 window.location.href=loginUrl+'?errorReason='+errorMsg;
               }, 3000);
          
        }
        }
        ,
        error: (e:any) => console.error(e)
      });

                          
    
  }

  

showProgressBar()
{
  $("#divProcessing").show();
}

hideProgressBar()
{
  $("#divProcessing").hide();
}

disableBack() { window.history.forward(); }
}

function disableBack() { window.history.forward(); }
       
        window.onunload = function () { null };

function wait(arg0: number) {
  throw new Error('Function not implemented.');
}

