import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { ProfileComponent } from './profile/profile.component';
import { HomeComponent } from './home/home.component';
import { FailedComponent } from './failed/failed.component';
import { RPostAuthenticationComponent } from './rpost-authentication/rpost-authentication.component';
import { RpostAuthenticationLogoutComponent } from './rpost-authentication-logout/rpost-authentication-logout.component';
import { RpostAuthenticationResolverResolver } from './resolver/rpost-authentication-resolver.resolver';
import { EmailAutenticationComponent } from './email-autentication/email-autentication.component';
import { EmailAuthenticationResolver } from './resolver/email-authentication.resolver';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ResetPasswordReminderComponent } from './reset-password-reminder/reset-password-reminder.component';
import { PasswordExpiredComponent } from './password-expired/password-expired.component';
import { ErrorpageComponent } from './errorpage/errorpage.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { ResetPasswordSuccessComponent } from './reset-password-success/reset-password-success.component';
import { ResetPasswordErrorComponent } from './reset-password-error/reset-password-error.component';
const routes: Routes = [
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [MsalGuard]
  },
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'login-failed',
    component: FailedComponent
  },
  {
    /* path: 'rpost-authentication/:clientId', */
    path: 'rpost-authentication',
    component: RPostAuthenticationComponent,
    resolve: { authencationResolver: RpostAuthenticationResolverResolver}
  }
  ,
  {
    
    path: 'rpost-authentication-logout',
    component: RpostAuthenticationLogoutComponent
  }
  ,
  {
    
    path: 'email-authentication',
    component: EmailAutenticationComponent,
    resolve: {validateTokenDetails: EmailAuthenticationResolver}
  }
  ,
  {
    
    path: 'multifactor-authentication',
    component: EmailAutenticationComponent,
    resolve: {validateTokenDetails: EmailAuthenticationResolver}
  }
  ,
  {
    
    path: 'reset-password',
    component: ResetPasswordComponent
  }
  ,
  {
    
    path: 'reset-password-reminder',
    component: ResetPasswordReminderComponent
  }
  ,
  {
    
    path: 'password-expired',
    component: PasswordExpiredComponent
  }
  ,
  {
    
    path: 'resetpassword',
    component: PasswordResetComponent,
    resolve: {validateTokenDetails: EmailAuthenticationResolver}
  }
  ,
  {
    
    path: 'resetpasswordsuccess',
    component: ResetPasswordSuccessComponent
  }
  ,
  {
    
    path: 'resetpassworderror',
    component: ResetPasswordErrorComponent
  }
  ,
  { path:'errorpage/:errorDesc', component:ErrorpageComponent}
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // Don't perform initial navigation in iframes or popups
    initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universal
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
