import { Component } from '@angular/core';

@Component({
  selector: 'app-reset-password-success',
  templateUrl: './reset-password-success.component.html',
  styleUrls: ['./reset-password-success.component.css']
})
export class ResetPasswordSuccessComponent {

  ngOnInit(): void {
  
    $('#divProcessingCommon').css("display","none");
    
    
  }

}




