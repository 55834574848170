import { Injectable, inject } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  ResolveFn
} from '@angular/router';
import { Observable, catchError, map, of, pipe } from 'rxjs';
import { IMFATokenResponseDetails } from '../Model/MFA/MFATokenResponse';
import { CommonService } from '../service/common/common.service';
import { MFAAuthenticationService } from '../service/mfaauthentication.service';

// @Injectable({
//   providedIn: 'root'
// })
export const EmailAuthenticationResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  //router:Router,
  mfaSvc: MFAAuthenticationService = inject(MFAAuthenticationService)
): Observable<{}> =>
    
    mfaSvc.ValidateMFATokenFromServer()
    .pipe(
      map((result: any) => {
        
        return result;
      })
      //,delay(5000)
    )
    .pipe(
        catchError((err) => {
          return of('No data' + err);
        })
      );
