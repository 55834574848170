<main class="login_wrapper my3" style="height: 100vh; background: aliceblue;">
        <div class="session d-flex justify-content-center">
    <div class="div-center">
        <div class="div-center-cn text-center">
            <i class="fa fa-check" aria-hidden="true"></i>
        <h4 class="text-success fw-600">{{"ResetPasswordSuccess.PasswordChangeSuccessMsg" | translate}}</h4>
    </div>
    </div>
        </div>
</main>

