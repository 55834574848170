import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '../app-config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MFATokenRequestModel } from '../Model/MFA/MFATokenRequestModel';
import { IMFATokenResponseDetails } from '../Model/MFA/MFATokenResponse';
import { Observable, catchError, map, of, pipe, retry, throwError } from 'rxjs';
import { VerifyAccessCodeModel } from '../Model/MFA/VerifyAccessCodeModel';
import { IVerifyAccessCodeResponseDetails } from '../Model/MFA/VerifyAccessCodeResponse';
import { CommonService } from './common/common.service';
import { AppError } from '../common/class/app-error';
import { IValidateMFATokenResponseDetails } from '../Model/MFA/ValidateMFATokenResponse';
import { IResendOTPResponseDetails } from '../Model/MFA/ResendOTPResponseModel';
import { CommonUtilService } from './common-util.service';
import { VerifyOTPRequestModel } from '../Model/MFA/VerifyOTPRequestModel';
import { IVerifyOTPResponseDetails } from '../Model/MFA/VerifyOTPResponseModel';
import { TranslateService } from '@ngx-translate/core';
import { ErrorReason } from '../Model/ErrorReasonModel';
import { ResultContent_PasswordPolicySetting } from '../Model/ResetPassword/PasswordPolicySettingResponse';

@Injectable({
  providedIn: 'root'
})
export class MFAAuthenticationService {
  baseAuthAPIUrl: any;
  qs: any;
  mfaTokenRequestModelObj: any;
  callBackUrl:any;
  qsBase64: any;
  mfaToken:any;
  sourceClientId:any;
  appLang: any;
  rcapClientId:any;
  loginUrl:any;
  constructor(private http: HttpClient,private config: AppConfigService
    ,private router:Router,private route: ActivatedRoute
    ,private commonUtilSvc:CommonUtilService
    ,private translate: TranslateService
    ) {
    this.baseAuthAPIUrl = this.config.baseAuthApiUrlCfg;
    this.rcapClientId=this.config.rcapClientId;
    
    
   }

  

  // ValidateMFAToken(reqObj:MFATokenRequestModel):Observable<IMFATokenResponseDetails> 
  // {
  //     let mfaTokenAPIUrl=this.baseAuthAPIUrl+'/api/v1/MFA/Token/Validate';
  //     const data=JSON.stringify({emailAddress: reqObj.emailAddress
  //         ,password: reqObj.password,client_id: reqObj.clientId,sourceClient_id:reqObj.sourceClientId
  //         ,mfaToken:reqObj.mfaToken});
  //     return this.http.post<IMFATokenResponseDetails>(mfaTokenAPIUrl, data , {
  //       headers: new HttpHeaders({
  //         'Content-Type':  'application/json'
  //       })
  //     })
  //     .pipe(
  //       map((result: any) => {
          
          
  //         return result;
  //       })
        
  //     ); 
      

  // }
  SetLanguage(lang:string)
  {
    this.appLang=lang;
              if(this.appLang!=null)
                this.translate.setDefaultLang(this.appLang);
              else
                this.translate.setDefaultLang('en-us');
  }
  ValidateMFATokenFromServer()
  {
    this.qsBase64=this.commonUtilSvc.GetParameterByName("?qs",window.location.href)!;
    
    let qsPlainText=this.commonUtilSvc.ConvertBase64ToString(this.qsBase64);
    console.log(qsPlainText);
    let emailAddress='';
    let password= ''; 
    let clientId= this.rcapClientId; 
    this.sourceClientId= this.commonUtilSvc.GetParameterByName("?SourceClientId",qsPlainText)!;
    this.mfaToken=this.commonUtilSvc.GetParameterByName("MFAToken",qsPlainText)!;
    localStorage.setItem("MFAToken",this.mfaToken);
    this.callBackUrl=this.commonUtilSvc.GetParameterByName("CallBackUrl",qsPlainText)!;

    let loginUrl=this.commonUtilSvc.GetParameterByName("LoginUrl",qsPlainText)!;
    
    localStorage.setItem("LoginUrl_"+this.mfaToken,loginUrl);
    localStorage.setItem("LoginUrl_Error",loginUrl);
    let redirectUri=this.callBackUrl;//Todo: Get value based on OTPVerify/PWdChange flags
    localStorage.setItem("RedirectURI_"+this.mfaToken,redirectUri);
    localStorage.setItem("SourceClientId_"+this.mfaToken,this.sourceClientId);
    localStorage.setItem("ClientId_"+ this.mfaToken,clientId);
    let fromUrl=this.commonUtilSvc.GetParameterByName("From",qsPlainText);
    
    let mfaTokenAPIUrl=this.baseAuthAPIUrl+'/api/v1/MFA/Token/Validate';
      
      const data=JSON.stringify({mfA_Token:this.mfaToken,RedirectURI:redirectUri,sourceClient_Id:this.sourceClientId
                                  ,client_Id:clientId});
      return this.http.post<IMFATokenResponseDetails>(mfaTokenAPIUrl, data , {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      })
      .pipe(
        map((result: any) => {
          
          const response =result as unknown as IValidateMFATokenResponseDetails;
          if(response!=null && response.resultContent!=null)
          {
            
              let resultOutput=response.resultContent;
              //Begin 
              let numOfDaysToExpire: number =resultOutput.passwordExpiresIn;
              let enforcePasswordPolicy:number= resultOutput.enforcePasswordPolicy;
              if(resultOutput.enforcePasswordPolicy!=null && resultOutput.enforcePasswordPolicy!=undefined 
                && resultOutput.enforcePasswordPolicy==1)//Rportal setting next login
              {
                let  enforcePasswordPolicy: number =resultOutput.enforcePasswordPolicy;
                localStorage.setItem("enforcePasswordPolicy_"+this.mfaToken,enforcePasswordPolicy.toString());
              }
              //End
              //Begin S1-3356 -RCAP UI changes for MFA workflow via Mobile Authentication
              let phoneNumber='';
              if(resultOutput.phoneNumber!='' && resultOutput.phoneNumber!=null && resultOutput.phoneNumber!=undefined)
              {
                  phoneNumber= resultOutput.phoneNumber;
                  localStorage.setItem("phoneNumber_"+this.mfaToken,phoneNumber);
              }
              //End S1-3356
              //Begin S1-3262
              /* console.log("MFAEnabled value is " + resultOutput.mfaEnabled); 
              console.log("PasswordChange value is "+ resultOutput.passwordChange);
              console.log("Reset password string value index in the url is "+ window.location.href.indexOf("resetpassword")); */
              let pwdPolicySettingResultOutPut: ResultContent_PasswordPolicySetting =response.resultContent.passwordPolicySetting;
              localStorage.setItem('PwdPolicySettingsLs_'+this.mfaToken, JSON.stringify(pwdPolicySettingResultOutPut));
              //alert(localStorage.getItem("PwdPolicySettingsLs_"+this.mfaToken));
              //End S1-3262
              localStorage.setItem("mfaToken",this.mfaToken);
              
               if(fromUrl!=null && fromUrl!='' && fromUrl!=undefined && fromUrl=='RPL') //Request came from WebAPI Email link,navigate to Resetpassword screen
               {
                  console.log("Request came from Reset Password Email Link");
                  localStorage.setItem("FromUrl_"+this.mfaToken,'RPL'); //S1-3262
                  this.router.navigate(['/reset-password']);
               } 
               else if(resultOutput.mfaEnabled && resultOutput.accessCodeEmailed)
               {
                //redirect to MFA Login screen
                console.log("MFA is enabled and accessCodeEmailed is true");  
                  if((resultOutput.passwordChange==-1 || resultOutput.passwordChange==0 || resultOutput.passwordChange==1) 
                    && window.location.href.indexOf("resetpassword")!=-1)
                    {
                        //redirect to MFA Login screen
                        console.log("MFA is enabled and passwordchange is expired(-1) or passwordchange is 0, but url contains resetpassword"); 
                        setTimeout(()=>{
                          
                          this.router.navigateByUrl('/multifactor-authentication?qs='+this.qsBase64, {replaceUrl:true});
                        });         
                    
                    }
                             
                
               }
              // else if(resultOutput.mfaEnabled && (resultOutput.passwordChange==-1 || resultOutput.passwordChange==0)
              //       && window.location.href.indexOf("resetpassword")!=-1
              //     )
              // {
              //   //redirect to MFA Login screen
              //   console.log("MFA is enabled and passwordchange is expired(-1) or passwordchange is 0, but url contains resetpassword"); 
              //   setTimeout(()=>{
                  
              //     this.router.navigateByUrl('/multifactor-authentication?qs='+this.qsBase64, {replaceUrl:true});
              //   });         
              //   //this.router.navigate(['/multifactor-authentication'],{queryParams: {qs: this.qsBase64 }});
              // }
               else if(!resultOutput.mfaEnabled && resultOutput.passwordChange==0)
               {
                //alert(resultOutput.passwordChange);
                  console.log(resultOutput.enforcePasswordPolicy);
                  if(resultOutput.enforcePasswordPolicy!=null && resultOutput.enforcePasswordPolicy!=undefined 
                    && resultOutput.enforcePasswordPolicy==1)//Rportal setting ->EncforcePasswordPolicyOn is next login(NextLoginAttempt)
                  {
                    console.log("MFA not enabled, passwordchange is 0 and enforcePasswordPolicy is 1");
                    this.router.navigate(['/reset-password']);
                  }
                  else
                  {
                    // redirect to callback back because MFA is not enabled and PAsswordChange is also 0
                    let errorReason= ErrorReason.MFA_NOT_ENABLED_PWD_CHANGE_NOT_REQUIRED;//'MFA not enabled  and PAssword change is not required';
                    this.RedirectToCallBackUrl(errorReason,loginUrl);
                  }
                
               }
               else if(!resultOutput.mfaEnabled && resultOutput.passwordChange==1 
                  && window.location.href.indexOf("resetpassword")==-1)
               {
                  //redirect to Change password screen 
                  //request came from client app for backward compatiability
                  console.log("mfa is not enabled and password change is enabled");
                  console.log(resultOutput.enforcePasswordPolicy);
                  
                  //Test case:            RPortal Login security set to - YES
                  //Enforce Password Policy on  :  Next Login attempt
                  //User status:         Password about to expire in few days
                  //Password not matched: Yes 
                  this.RedirectToResetPasswordOrResetPwdReminderScreen(numOfDaysToExpire,enforcePasswordPolicy,resultOutput.passwordChange);
                  
                  
               }
               else if(!resultOutput.mfaEnabled && resultOutput.passwordChange==1 
                  && window.location.href.indexOf("resetpassword")!=-1)
               {
                 if(fromUrl=='RPL') //Request came from Email Reset password click button 
                  {
                    //redirect to reset password screen 
                    console.log("mfa is not enabled, password change is enabled and url contains resetpassword string and Request came from Email Reset password click button");
                    this.router.navigate(['/reset-password']);
                  }
                  else //request came from client app
                  {
                    console.log("mfa is not enabled, password change is enabled and url contains resetpassword string and request came from client app");
                    //this.RedirectToResetPasswordReminderScreen(resultOutput.passwordExpiresIn);
                    
                    //Test case:            RPortal Login security set to - YES
                    //Enforce Password Policy on  :  Next Login attempt
                    //User status:         Password about to expire in few days
                    this.RedirectToResetPasswordOrResetPwdReminderScreen(numOfDaysToExpire,enforcePasswordPolicy,resultOutput.passwordChange);
                  }
               }
               else if(!resultOutput.mfaEnabled && resultOutput.passwordChange==-1)
               {
                console.log("MFA is not enabled and passwordChange is -1(Expired)");
                this.RedirectToResetPasswordOrResetPwdReminderScreen(numOfDaysToExpire,enforcePasswordPolicy,resultOutput.passwordChange);
                //redirect to password expiry screen
                
                //this.router.navigate(['/password-expired']);

               }

          }
          
          return result;
        })
        
      )
      .pipe(catchError(this.handleError));
      
  }
  RedirectToResetPasswordOrResetPwdReminderScreen(numOfDaysToExpire:number,enforcePasswordPolicy:number,passwordChangeType:number)
  {
    if(passwordChangeType==1 && numOfDaysToExpire >0 && enforcePasswordPolicy==1)
    {
      console.log("RPortal Login security set to - YES , Enforce Password Policy on  :  Next Login attempt, User status: Password about to expire in "+numOfDaysToExpire+" days, Password not matched: Yes");   
    }
    else if(passwordChangeType==1 && numOfDaysToExpire ==0 && enforcePasswordPolicy==1)
    {
      console.log("RPortal Login security set to - YES , Enforce Password Policy on  :  Next Login attempt, User status: Password about to expire in today, Password not matched: Yes")  
    }
    else if(passwordChangeType==-1 && numOfDaysToExpire ==0 && enforcePasswordPolicy==1)
    {
      console.log("RPortal Login security set to - YES , Enforce Password Policy on  :  Next Login attempt, User status: Password has expired, Password not matched: Yes")  
    }
    else if(passwordChangeType==-1 && numOfDaysToExpire ==0 && enforcePasswordPolicy==0)
    {
      console.log("User status: Password has expired")  
    }
    else if(passwordChangeType==1 && numOfDaysToExpire ==0 && enforcePasswordPolicy==0)
    {
      console.log("User status: Password about to expire in today")  
    }
    else if(passwordChangeType==1 && numOfDaysToExpire >0 && enforcePasswordPolicy==0)
    {
      console.log("User status: Password about to expire in "+numOfDaysToExpire+" days")  
    }
    else{
      console.log("numOfDaysToExpire: " + numOfDaysToExpire);
      console.log("enforcePasswordPolicy: "+ enforcePasswordPolicy);
      console.log("passwordChangeType:" + passwordChangeType);
    }

   
    if(enforcePasswordPolicy==1)
    {
      console.log("MFA not enabled, passwordchange is +"+passwordChangeType + " and enforcePasswordPolicy is 1(NextLoginAttempt)"); //As per issue reported by sreelekha in the email with subject "RE: Open issues we have seen for Password policy changes in Production" on 17th jan 2025
      this.router.navigate(['/reset-password']);
    }
    else if(passwordChangeType==-1)
    {
      this.router.navigate(['/password-expired']);
    }
    else //redirect to resetpassword reminder screen
    {
      this.RedirectToResetPasswordReminderScreen(numOfDaysToExpire); 
    }
  }
  RedirectToResetPasswordReminderScreen(passwordExpiresIn:number)
  {
    
    console.log("mfa is not enabled and password change is enabled");
    var numOfDaysToExpire =passwordExpiresIn ; 
    var numOfDaysToExpireBase64String=this.commonUtilSvc.ConvertStringToBase64(numOfDaysToExpire.toString());
    this.router.navigate(['/reset-password-reminder'],{queryParams: {qs: numOfDaysToExpireBase64String }});
    
  }
  ReloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
}
  ReSendOTP(mfaToken:string,sourceClientId:string,clientId:string,redirectUri:string) : Observable<any>
  {
    let resendOTPAPIUrl=this.baseAuthAPIUrl+'/api/v1/MFA/ReSendOTP';
    const data=JSON.stringify({MFA_Token:mfaToken
      ,SourceClient_Id:sourceClientId
      ,Client_Id:clientId
      ,RedirectURI: redirectUri
      });
      return this.http.post(resendOTPAPIUrl, data,
        {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        })
        .pipe(catchError(this.handleError));
        
  }
  private extractData(res: any) {
    let body = res;
    return body;
  }
  private handleErrorObservable(error: any) {
    console.error(error.message || error);
    return throwError(error);
  } 

  VerifyOTP(data: VerifyOTPRequestModel): Observable<any> {
    let verifyOTPAPIUrl=this.baseAuthAPIUrl+'/api/v1/MFA/VerifyOTP'
    
    return this.http.post(verifyOTPAPIUrl, data,
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    })
    .pipe(catchError(this.handleError));
    
    
  }

  
  private handleError(err: HttpErrorResponse ): Observable<AppError> {
    const appError = new AppError(err);
    const errorStatus = err.status;
    const errorStatusText = err.statusText;
    const error: any = err.error;
    let errorMsg :any;
    let msgId = '';
    
    if (error.Message !== undefined && Array.isArray(error.Message)&& err.error.Message[0]!=undefined) {
        errorMsg = err.error.Message[0].Message;
        msgId = err.error.Message[0].MessageId;
    } else {
      if (error.error_description) {
        errorMsg = error.error_description;
      } else {
        if (error.message) {
          errorMsg = error.message;
          if(error.resultContent!=null)
          {
            let errorResultContent=error.resultContent;
            errorMsg=(errorResultContent.errors[0]!=undefined)?errorResultContent.errors[0]:errorMsg;
          }
          if(error.statusCode=="401") //Unauthorized
          {
            
          }
              
          msgId = (error.MessageId) ? error.MessageId : '';
        } else {
          errorMsg = errorStatusText;
        }
      }
    }

    
    const retMsg = `${errorMsg.message}`;
    appError.Message = retMsg;
    appError.MessageId = msgId;

    return of(appError);
  }

  RedirectToCallBackUrl(errorMesg:any,callBackUrl:string)
  {
    
    callBackUrl= callBackUrl+"?errorReason="+errorMesg;
   window.location.href= callBackUrl;
  }
}

