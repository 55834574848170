<main class="login_wrapper my3" style="height: 100vh; background: aliceblue;">
    <div id="divProcessing" class="processing text-center" style="display: none;justify-content: center;align-items: center;width: 100%;position: relative;">
       <img src="../assets/images/orange-loader.gif"
          alt="Processing..."  style="position: absolute;top: 221px;z-index: 999; width:175px;"
          />
    </div>
    <div class="session">
        <div>
            <div>
                <a href="" class="d-flex align-items-center justify-content-center"><img src="assets/images/R1-logo.svg"
                        alt="RPOST" style="height: 75px;"></a>
            </div>
            <div class="global-container">

                <div class="card login-form">

                        <div class="card-body p-0">
                            <div id="divErrorMsg" class="position-relative d-flex justify-content-center mb-1"
                        style="display:none !important; background-color: #f2dede;">

                        <p id="parErrorMsg" class="error-text mb-0">{{"EmailAuthentication.IncorrectOrExpiredAccessCode" |
                         translate}}
                      </p>
                        

                    </div>
                    <div id="divSuccessMsg" class="position-relative d-flex justify-content-center mb-1"
                        style="display:none !important; background-color: palegreen;">

                        <p id="parSuccessMsg" class="text-success mb-0"></p>
                        <!-- <p id="parSuccessCallBakMsg" class="success-text mb-0"></p> -->
                        <!-- <p id="parSuccessCallBakMsg" class="success-text mb-0">Please click <a id="ancSignHere" href="{{SignInUrl}}" >here</a> to Sign In.</p> -->

                    </div>

                        <h3 class="card-title text-center">{{"ResetPassword.CreateNewPasswordTitle" |
                      translate}}
                   </h3>
                   <div class="card-text reset-form">
                      <form class="mt-0 pt-0" novalidate [formGroup]="resetForm">
                        <b>{{"ResetPassword.PasswordMustContainLabelText" | translate}}:</b>
                        <ul style="list-style-type: none; padding: 10px 0px 0 20px;">
                            <li>
                               <i [ngClass]="isPasswordLengthValid() ? 'fa fa-check checked' : 'fa fa-minus unchecked'"
                                  aria-hidden="true"></i>
                               <span [ngClass]="isPasswordLengthValid() ? 'checked' : 'unchecked1'">&nbsp; &nbsp; 
                               {{atleastText | translate  }} {{pwdLengthNumberText | translate  }} {{pwdLengthPwdRuleText | translate  }}</span>
                            </li>
                            <li>
                               <i [ngClass]="hasUpperCaseLetters() ? 'fa fa-check checked' : 'fa fa-minus unchecked'"
                                  aria-hidden="true"></i>
                               <span [ngClass]="hasUpperCaseLetters() ? 'checked' : 'unchecked1'">&nbsp;
                               &nbsp; {{atleastText | translate  }} {{upperCaseNumberText | translate  }} {{upperCasePwdRuleText | translate  }}</span>
                            </li>
                            <li>
                               <i [ngClass]="hasLowerCaseLetters() ? 'fa fa-check checked' : 'fa fa-minus unchecked'"
                                  aria-hidden="true"></i>
                               <span [ngClass]="hasLowerCaseLetters() ? 'checked' : 'unchecked1'">&nbsp;
                               &nbsp; {{atleastText | translate  }} {{lowerCaseNumberText | translate  }} {{lowerCasePwdRuleText | translate  }}</span>
                            </li>
                            <li>
                               <i [ngClass]="hasSpecialChars() ? 'fa fa-check checked' : 'fa fa-minus unchecked'"
                                  aria-hidden="true"></i>
                               <span [ngClass]="hasSpecialChars() ? 'checked' : 'unchecked1'">&nbsp; &nbsp;
                               {{atleastText | translate  }} {{specialCharNumberText | translate  }} {{specialCharPwdRuleText | translate  }}</span>
                            </li>
                            <li>
                               <i [ngClass]="hasNumbers() ? 'fa fa-check checked' : 'fa fa-minus unchecked'"
                                  aria-hidden="true"></i>
                               <span [ngClass]="hasNumbers() ? 'checked' : 'unchecked1'">&nbsp; &nbsp;
                               {{atleastText | translate  }} {{numberText | translate  }} {{numberPwdRuleText | translate  }}</span>
                            </li>
                         </ul>
                         <div class="form-group">
                            <label for="exampleInputPassword1" class="f-7 mb-1">{{"ResetPassword.PasswordLabelText" |
                            translate}}:</label>
                            <div class="input-group" id="show_hide_password">
                               <input id="txtPassword" [type]="fieldTextType ? 'text' : 'password'"
                               class="form-control" formControlName="txtPassword"
                               style="font-size: 14px; border-right: none;"
                               required (blur)="onPasswordBlur($event)" (keydown.space)="$event.preventDefault()"
                               placeholder="New Password" />
                               <div class="input-group-append">
                                  <span class="input-group-text">
                                  <i class="fa" [ngClass]="{
                                  'fa-eye-slash': !fieldTextType,
                                  'fa-eye': fieldTextType
                                  }" (click)="toggleFieldTextType()"></i>
                                  </span>
                               </div>
                            </div>
                         </div>
                         <div class="form-group">
                            <label for="exampleInputPassword2" class="f-7 mb-1">{{"ResetPassword.ConfirmPasswordLabelText" |
                            translate}}:</label>
                            <div class="input-group" id="hide_show_password">
                               <!-- <a href="" class="position-absolute input-group-addon">
                                  <i class="fa fa-eye-slash" aria-hidden="true"></i></a>
                                  <input class="form-control" type="password" placeholder="Confirm Password" style="font-size: 14px;  border-radius: 0.25rem !important;">  -->
                               <input id="txtConfirmPassword"
                               [type]="fieldTextType_ConfirmPwd ? 'text' : 'password'" class="form-control"
                               formControlName="txtConfirmPassword"
                               style="font-size: 14px; border-right: none;"
                               required  (blur)="onConfirmPasswordBlur($event)" (keydown.space)="$event.preventDefault()" placeholder="Confirm Password" />
                               <div class="input-group-append">
                                  <span class="input-group-text">
                                  <i class="fa" [ngClass]="{
                                  'fa-eye-slash': !fieldTextType_ConfirmPwd,
                                  'fa-eye': fieldTextType_ConfirmPwd
                                  }" (click)="toggleFieldTextType_ConfirmPwd()"></i>
                                  </span>
                               </div>
                               <div class="text-danger popup" *ngIf="validMatchPassword">
                                  <span class="popuptext" [ngClass]="{'show': (validMatchPassword)}">
                                  {{"ResetPassword.PwdAndConfirPwdValidationMsg" | translate}} </span>
                               </div>
                            </div>
                         </div>
                        
                         <div class="button-sty">
                           
                            <button  class="button-disable btn btn-lg btn-block" id="btnSubmit"
                            [disabled]="resetForm.invalid || !isPasswordValid() || resetForm.value.txtPassword != resetForm.value.txtConfirmPassword"
                            (click)="resetPassword(resetForm)"
                            [ngClass]="{'button-disable': resetForm.invalid  || !isPasswordValid() || resetForm.value.txtPassword != resetForm.value.txtConfirmPassword, 'verify-btn': !resetForm.invalid || isPasswordValid() || resetForm.value.txtPassword == resetForm.value.txtConfirmPassword}">
                            {{"ResetPassword.ChangePasswordButtonText" | translate}}
                            </button>
                         </div>
                         <!-- <p class="mt-2 mb-0" style="font-size: 13px;"><span style="font-weight: 600;">Note:</span> Password should not be same as previous three passwords used with us, it should not contain four consecutive digits, nor four consecutive letters of your username or email address.</p> -->
                      </form>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </div>

</main>
