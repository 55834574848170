import { Injectable } from '@angular/core';
import { ConversionUtils } from 'turbocommons-ts';
import { ErrorReason } from '../Model/ErrorReasonModel';
import { JwtToken, ResponseMsgToClient } from '../Model/MFA/ResponseMsgToClient';

@Injectable({
  providedIn: 'root'
})
export class CommonUtilService {
  [x: string]: any;
  checkTimer:any;
  constructor() { }
  
  setCookie (cname: string) {
    // start timer to wait for cookie
    this.checkTimer = window.setInterval(function () {

        if (document.cookie.indexOf(cname) !== -1) {
            // hide spinner
            
            $('#divDownloading').fadeOut(4000);
            //window.clearTimeout(checkTimer);
            document.cookie = encodeURIComponent(cname) + "=deleted; expires=" + new Date(0).toUTCString();
            
        }
    }, 100);
};

 expireCookie(cName: string | number | boolean) {
    document.cookie =
        encodeURIComponent(cName) + "=deleted; expires=" + new Date(0).toUTCString();
}

  ConvertObjToJsonString(obj:any)
  {
    let response;
    response= JSON.stringify(obj);
    return response;
  }

  ConvertStringToBase64(originalString:string)
  {
    return ConversionUtils.stringToBase64(originalString);
  }
  ConvertBase64ToString(base64String:string)
  {
    return ConversionUtils.base64ToString(base64String);
  }

  GetRandomNumber()
  {
    const randomInt = (min: number, max: number) =>
    Math.floor(Math.random() * (max - min + 1)) + min;
    return randomInt(0, 5); // 2
  }

  

  
  GetQueryStringValue(inputParamter:string)
  {
    let queryString=(inputParamter!=null)?inputParamter.split('?')[1]:'';

    let rpostClientIdQs=this.GetParameterByName(queryString);
    let callBackUrlQs=this.GetParameterByName(queryString);
    let loginUrlQs= this.GetParameterByName(queryString);
    let emailAddress= this.GetParameterByName(queryString);

  }

  GetParameterByName(name:string, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

getBrowserName(): string {
  const agent = window.navigator.userAgent.toLowerCase();
  const browser =
    agent.indexOf('edge') > -1 ? 'Microsoft Edge'
      : agent.indexOf('edg') > -1 ? 'Chromium-based Edge'
      : agent.indexOf('opr') > -1 ? 'Opera'
      : agent.indexOf('chrome') > -1 ? 'Chrome'
      : agent.indexOf('trident') > -1 ? 'Internet Explorer'
      : agent.indexOf('firefox') > -1 ? 'Firefox'
      : agent.indexOf('safari') > -1 ? 'Safari'
      : 'other';

  return browser;
}

generateDataShareIframeHtmlElement(callbackUrl:string,rpostClientId:string)
   {

    const iframe = document.createElement('IFRAME');
    iframe.id = 'receiverWindow_'+rpostClientId;
    iframe.style.display = "none";
    iframe.style.width="1px";
    (<HTMLIFrameElement>iframe).src = callbackUrl; 
    document.body.appendChild(iframe);
    
   }
   showProgressBar()
   {
     
     $("#divProcessing").css("display","flex");
   }
   
   hideProgressBar()
   {
     
     $("#divProcessing").css("display","none");
   }

   hideCommonProgressBar()
   {
    $('#divProcessingCommon').css("display","none");
   }
postMessageToClient(responseToPost:string, iframeId:string, callBackUrl:string, clientId:string
                      ,clientAppLoginUrl:string, callingMethod:string='') {
  const date = new Date(); 
  
  
  var iframe = document.getElementById("receiverWindow_"+clientId); 
  console.log(iframe);
  if (iframe == null) { 
    this.generateDataShareIframeHtmlElement(callBackUrl,clientId);
    iframe = document.getElementById("receiverWindow_"+clientId); 
  }
  const iWindow = (iframe as HTMLIFrameElement).contentWindow!;
  let msgToPost= this.ConvertStringToBase64(responseToPost); 
   if(msgToPost!=null && msgToPost!='')
     console.log('Message is not empty');
  else
    console.log('Message is empty');
   
  
  if(iframe!=null && msgToPost!=null && msgToPost!='' )
  {
     
      setTimeout( () => {
        this.clearLocalStorage();
        iWindow.postMessage(msgToPost, '*');
        
        console.log('callbackUr is ' + callBackUrl);
        window.location.href=callBackUrl; 
    }, 2000);
  }
  else
  {
    console.log("message to post is empty");
    let errorDesc='';
    if(iframe ==null)
    {
      errorDesc=ErrorReason.COULD_NOT_ABLE_TO_SEND_MESSAGE;
      
      this.redirectToClientAppLoginWhenErrorOccured(errorDesc,clientAppLoginUrl);
      console.log(errorDesc);
    }
    else if(msgToPost==null || msgToPost=='')
    {
      errorDesc=ErrorReason.MESSAGE_TO_POST_EMPTY_RESPONSE;
      console.log(errorDesc);
    }

  }
  
 

}

prepareMsgToPost_Json(mfaToken:string,trustDeviceInfo:string,jwtToken:JwtToken
  ,trustDeviceExpirationDate:string,redirectedFrom:string="MFA")
{
  let respMsgToClient=new ResponseMsgToClient();
  respMsgToClient.MFAToken=mfaToken;
  respMsgToClient.TrustDeviceInfo=trustDeviceInfo;
  respMsgToClient.RedirectedFrom=redirectedFrom;
  respMsgToClient.JwtToken=jwtToken;
  respMsgToClient.TrustDeviceExpirationDate=trustDeviceExpirationDate;
  return this.ConvertObjToJsonString(respMsgToClient);
}

clearLocalStorage()
{
  localStorage.clear();
}

redirectToClientAppLoginWhenErrorOccured(errorDesc:string,clientAppLoginUrl:string)
{
  console.log("error is  " + errorDesc);
  this.clearLocalStorage();
  window.location.href= clientAppLoginUrl +"?errorReason="+errorDesc;
}

getBrowserUserAgent()
{
  return window.navigator.userAgent;
}

displaySpinner()
   {
    
    $('#pnlSpinner').css("display","block");
   }

   hideSpinner()
   {
    $('#pnlSpinner').css("display","none");
   }

   displayValidationErroMsg(errorMsgToDisplay:string)
  {
    $('#divErrorMsg').show();
    $('#parErrorMsg').text(errorMsgToDisplay);
  }
  hideValidationErroMsg()
  {
    $('#divErrorMsg').hide();
    $('#parErrorMsg').text('');
  }
  displaySuccessMsg(msg:string)
  {
    $('#divSuccessMsg').show();
    $('#parSuccessMsg').text(msg);
  }
  hideSuccessMsg()
  {
    $('#divSuccessMsg').hide();
    $('#parSuccessMsg').text('');
  }

getMaskedPhoneNumber(phoneNumber:string):string
{
  var maskedPhoneNumber=phoneNumber;
  if(phoneNumber!=null && phoneNumber!='' && phoneNumber!=undefined)
  {
    var loopCounter=0;
    let lastPosition=phoneNumber.length-3;
   
    for(var i=0; i<phoneNumber.length; i++)
    {
      loopCounter=loopCounter+1;

      if(loopCounter>3 && loopCounter<lastPosition)
      {
          maskedPhoneNumber=replaceAtPosition(maskedPhoneNumber,loopCounter);

      }
      
    }
  }
  
  return maskedPhoneNumber;
}


}

function replaceAtPosition(originalString: string,index: any) {
  
  return  setCharAt(originalString,index,'x');
  
}

function setCharAt(str: string,index: number,chr: string) {
  if(index > str.length-1) return str;
  return str.substring(0,index) + chr + str.substring(index+1);
}






