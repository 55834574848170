<!-- <img class="wave" src="../assets/images/wave_yellow.svg" style="width: 100%;"> -->
<main class="login_wrapper my3" style="height: 100vh; background: aliceblue;">
    <div id="divProcessing" class="processing text-center" style="display: flex;justify-content: center;align-items: center;width: 100%;position: relative;">
        <img src="../assets/images/orange-loader.gif"
              alt="Processing..."  style="position: absolute;top: 221px;z-index: 999; width:175px;"
           />
    </div>
    <div class="container">
        <div id="divErrorMsg" class="position-relative  justify-content-center"
            style="display:none !important; top: 156px; font-size: 15px !important;">
            <p id="parErrorMsg" class="error-text mb-0">{{"EmailAuthentication.IncorrectOrExpiredAccessCode" |
                translate}}</p>

            </div>
            <div id="divSuccessMsg" class="position-relative  justify-content-center"
            style="display:none !important; top: 156px; font-size: 15px !important;">

            <p id="parSuccessMsg" class="text-success mb-0"></p>

            </div>
        <div class="session d-flex justify-content-center">
            
            <form action="" class="log-in" autocomplete="off" [formGroup]="mfaForm">
                <a  href="" class="d-flex align-items-center justify-content-center"><img src="assets/images/R1-logo.svg"
                        alt="RPOST" style="height: 75px;"></a>
                <h3 class="custom-Multi-Factor" style="font-size: 20px;">{{"EmailAuthentication.HeaderTitle" |
                    translate}}</h3>
                <p *ngIf="phoneNumber==''" class="mb-0">{{"EmailAuthentication.AccessCodeTitle" | translate}}</p>
                <p *ngIf="phoneNumber==''" class="mb-2">{{maskedEmailAddress}}.</p>
                <p *ngIf="phoneNumber!=''" class="mb-0">{{"MobileAuthentication.AccessCodeTitle" | translate}}</p>
                <p *ngIf="phoneNumber!=''" class="mb-2">{{maskedPhoneNumber}}.</p>
                <!-- <div id="divErrorMsg" class="position-relative d-flex justify-content-center"
                    style="display:none !important;">

                    <p id="parErrorMsg" class="error-text mb-0">{{"EmailAuthentication.IncorrectOrExpiredAccessCode" |
                        translate}}</p>
                   

                </div>
                <div id="divSuccessMsg" class="position-relative d-flex justify-content-center"
                    style="display:none !important;">

                    <p id="parSuccessMsg" class="text-success mb-0"></p>
                  
                </div> -->
                <!-- <div class="floating-label d-flex justify-content-center"> -->
                <div class="input-group justify-content-center mb-2" id="show_hide_password" >
                    <!-- <a ng-href=""  class="position-relative input-group-addon"><i class="fa fa-eye-slash" aria-hidden="true"></i></a><input id="txtOTP" (keyup)="tabOut($event)" maxlength="6"  placeholder="Enter code" class="form-control" type="password" autocomplete="off" style="font-size: 14px; max-width: 220px; border-radius: 0.25rem !important;"> -->
                    <input id="txtOTP" (paste)="onPaste($event)" (keyup)="tabOut($event)" maxlength="6" placeholder="Enter code" name="txtOTP"
                        [type]="fieldTextType ? 'text' : 'password'" class="form-control" 
                        style="font-size: 14px; max-width: 125px; border-right: none; border: 1px solid lightslategrey; border-right: none;" />
                    <div class="input-group-append">
                        <span class="input-group-text">
                            <i class="fa" [ngClass]="{
                          'fa-eye-slash': !fieldTextType,
                          'fa-eye': fieldTextType
                        }" (click)="toggleFieldTextType()"></i>
                        </span>
                    </div>
                </div>
                <!-- </div>    -->
                <div class="form-check d-flex align-items-center mt-1 mb-3 custom-no-wrap">
                    <input type="checkbox" class="form-check-input" id="chkTrustDeviceCheckBox"
                        (change)="saveTrustDeviceInformation($event)" style="margin-bottom: 5px; margin-right: 185px;">
                    <label class="form-check-label" for="exampleCheck1">{{"EmailAuthentication.TrustDeviceMessage" |
                        translate}}</label>
                </div>
                <div class="d-flex justify-content-center mb-1">
                    <button id="ancVerifyBtn" [disabled]="true" (click)="verifyOTP()"
                        class="verify-btn-disabled">{{"EmailAuthentication.VerifyButtonText" | translate}}</button>
                </div>
                <div>
                    <p class="text-center mb-1" style="color: #c4272f; font-weight: 700;">00:{{counter==0?'00':counter.toString().length>1?counter:'0'+counter}}</p>
                </div>
                <div class="text-center">
                    <p class="mb-1">{{"EmailAuthentication.DidNotReceiveCodeMessage" | translate}}
                        <button  id="ancResend" (click)="resendOTP(this)" class="btn-Disabled"
                            style="font-weight: 300; color: cornflowerblue; text-decoration: none; ">{{"EmailAuthentication.ResendButtonText"
                            | translate}}
                        </button>
                    </p>
                    <!-- <a href="" style="font-weight: 600; text-decoration:none; ">{{"EmailAuthentication.TryAnotherOptionButtonText" | translate}}</a> -->
                </div>
                <!-- <div id="divProcessing" class="processing text-center" style="position:relative;height:100px;display:block;">
                    <img src="../assets/images/new-loader.gif"
                         style="position: absolute; left: 50%;  top: 100%;  margin-left: -32px; margin-top: 402px; width:100px" alt="Processing..." width="50" />
                </div> -->

            </form>

        </div>
    </div>

</main>
<script>
    $("#txtOTP").keydown(function (e) {

        if (e.which == 9)
            alert("Hurray!!!");
    });


</script>