import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonUtilService } from '../service/common-util.service';

@Component({
  selector: 'app-reset-password-error',
  templateUrl: './reset-password-error.component.html',
  styleUrls: ['./reset-password-error.component.css']
})
export class ResetPasswordErrorComponent {
  errorMsg:any='';
  qs: any;
  stepper: any;

  constructor(private route: ActivatedRoute, private commonUtilSvc:CommonUtilService
    ){
    this.route.queryParams.subscribe(params => {
      
      this.qs = params['qs'];
      //this.numDaysToExpire=this.qs;
      this.errorMsg=this.commonUtilSvc.ConvertBase64ToString(this.qs);
     });
    
     //this.disableBrowserBackButtonAngular();
      
  }
  ngOnInit(): void {
  
    $('#divProcessingCommon').css("display","none");
    
    
  }
}
