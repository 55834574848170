import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonUtilService } from '../service/common-util.service';
import { ResetpasswordService } from '../service/resetpassword.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslationLoaderService } from '../translation-loader.service';
import { UserSessionService } from '../common/user-session.service';
import { QueryStringValuesModel } from '../Model/MFA/QueryStringValuesModel';
import { ResetPasswordRequestModel } from '../Model/ResetPassword/ResetPasswordRequestModel';
import { AppError } from '../common/class/app-error';
import { IResetPasswordResponseDetails } from '../Model/ResetPassword/ResetPasswordResponseModel';
import { FormBuilder, FormControl, Validators, ReactiveFormsModule, FormGroup } from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';
import { AppConfigService } from '../app-config.service';
import { LocationStrategy } from '@angular/common';
import { ErrorReason } from '../Model/ErrorReasonModel';
import { ResultContent_PasswordPolicySetting } from '../Model/ResetPassword/PasswordPolicySettingResponse';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent {
  appLang: any;
  qs: any;
  fieldTextType: any;
  fieldTextType_ConfirmPwd: any;
  showPassword: boolean | undefined;
  showConfirmPassword: boolean | undefined;
  resetForm: any;
  hasUppercaseFlag: boolean | undefined;
  hasLowercaseFlag: boolean | undefined;
  hasNumberFlag: boolean | undefined;
  hasSpecialCharFlag: boolean | undefined;
  isLengthValidFlag: boolean | undefined;
  validPassword: boolean = false;
  validPasswordPattern: boolean = false;
  validConfirmPassword: boolean = false;
  validConfirmPatternPassword: boolean = false;
  validMatchPassword: boolean = false;
  SignInUrl:any;
  failedAttempts:number=0;
  rcapClientId:string | undefined;
  stepper: any;
  atleastText: any;
  upperCasePwdRuleText: any;
  upperCaseNumberText: any;
  lowerCasePwdRuleText: any;
  lowerCaseNumberText : any;
  numberPwdRuleText: any;
  numberText:any;
  specialCharPwdRuleText: any;
  specialCharNumberText:any;
  pwdLengthPwdRuleText: any;
  pwdLengthNumberText:any;
  fromUrl: any;
  constructor(private route: ActivatedRoute, private commonUtilSvc:CommonUtilService
    , private restPwdSvc:ResetpasswordService
    ,private translate: TranslateService
    
    ,private langSvc: TranslateService
      ,private langLoaderSvc: TranslationLoaderService
      ,private user: UserSessionService
      ,private formBuilder: FormBuilder
      , private sanitizer:DomSanitizer
      ,private config: AppConfigService
      ,private location: LocationStrategy
      ,private router:Router
      ){
    this.route.queryParams.subscribe(params => {
      
      this.qs = params['qs'];
      
     });
     this.rcapClientId=this.config.rcapClientId;
     this.disableBrowserBackButtonAngular();
      
  }
  

  disableBrowserBackButtonAngular()
{
  
  history.pushState(null, null!, window.location.href);
    // check if back or forward button is pressed.
    this.location.onPopState(() => {
        history.pushState(null, null!, window.location.href);
        this.stepper.previous();
    });
}

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  toggleFieldTextType_ConfirmPwd() {
    this.fieldTextType_ConfirmPwd = !this.fieldTextType_ConfirmPwd;
  }
  disableButton(btnId:string)
{
  $('#'+btnId+'').removeClass('btn-Enabled');
  $('#'+btnId+'').addClass('btn-Disabled');
  $('#'+btnId+'').click(function() { return false; }); 
  // $('#ancResend').removeClass('btn-Enabled');
  // $('#ancResend').addClass('btn-Disabled');
}
enableButton(btnId:string)
{
  $('#'+btnId+'').removeClass('btn-Disabled');
  $('#'+btnId+'').addClass('btn-Enabled');

}
SubmitPassword()
{
  alert('test');
}
btnClick()
{
  alert('button clicked');

}
  /* test(event:any)
  {
    //alert(event.target.value);
    //$("#ancSubmit").removeAttr("onclick");
    if(event.target.value.length==6)
            {
              $("#btnSubmit" ).prop( "disabled", false );
              //this.enableButton('ancSubmit');
            }
    
  }

  
  disableSubmitButton()
  {
    $('#btnSubmit').removeClass("verify-btn");
    $('#btnSubmit').addClass("button-disable");

  }
  enableSubmitButton()
  {
    $('#btnSubmit').removeClass("button-disable");
    $('#btnSubmit').addClass("verify-btn");
  } */
  buildForm() {

    /* this.resetForm = this.formBuilder.group({
      txtPassword: new FormControl('', [Validators.required,
                                    Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*]).{8,25}$"),
                                    Validators.minLength(this.pwdLengthNumberText)]),
      txtConfirmPassword: new FormControl('', [Validators.required,
                                    Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*]).{8,25}$"),
                                    Validators.minLength(this.pwdLengthNumberText)])

    }); */

    this.resetForm = this.formBuilder.group({
      txtPassword: new FormControl('', [Validators.required,
                                    Validators.minLength(this.pwdLengthNumberText)]),
      txtConfirmPassword: new FormControl('', [Validators.required,
                                    Validators.minLength(this.pwdLengthNumberText)])

    });

}

  InitLang() {
    const lang =  this.user.getAppLanguage();
    const browserLang = navigator.language.toLowerCase();
    if (lang === browserLang) {
      this.langLoaderSvc.use(lang);
      this.appLang = lang;
    } else {
      this.langLoaderSvc.use(lang);
      this.appLang = browserLang;
    }
  }
  disableBack() { window.history.forward(); }
  async ngOnInit(): Promise<void> {
    this.commonUtilSvc.hideCommonProgressBar();
    //this.getMaskedEmailAddress_V1('ramesh.ne@rpostlabs.com');
    this.getPasswordRules();
    this.buildForm();
    this.commonUtilSvc.hideProgressBar();
    this.disableBack();
    
    }

    displayValidationErroMsg(errorMsgToDisplay:string)
  {
    
   
    $('#divErrorMsg').css("display","flex");
    $('#divErrorMsg').css("justify-content","center");
    $('#parErrorMsg').text(errorMsgToDisplay);
  }
  hideValidationErroMsg()
  {
   
   $('#divErrorMsg').attr("style", "display:none !important; background-color: #f2dede;");
    $('#parErrorMsg').text('');
  }

  displaySuccessMsg(msg:string)
  {

    $('#divSuccessMsg').css("display","flex");
    $('#divSuccessMsg').css("justify-content","center");
    $('#parSuccessMsg').text(msg);
  }
  hideSuccessMsg()
  {
   
    $('#divSuccessMsg').attr("style", "display:none !important; background-color: palegreen;");
    $('#parSuccessMsg').text('');
  }

    invokeResetPasswordAPI(pwd:string, confirmPwd:string)
    {
      this.commonUtilSvc.showProgressBar();
      this.hideSuccessMsg();
      this.hideValidationErroMsg();

      let mfaToken='';
    if(localStorage.getItem('MFAToken')!=null && localStorage.getItem('MFAToken')!=undefined)
    {
      mfaToken=localStorage.getItem('MFAToken')!;
    }
    
    // let msgToPost_Json='';
    // if(localStorage.getItem("MsgToPost_"+mfaToken)!=null && localStorage.getItem("MsgToPost_"+mfaToken)!=undefined)
    // {
    //   msgToPost_Json=localStorage.getItem("MsgToPost_"+mfaToken)!;
    // }
    // let randomNumber= this.commonUtilSvc.GetRandomNumber()+'_'+mfaToken;
    let callBackUrl='';
    if(localStorage.getItem("RedirectURI_"+mfaToken)!=null && localStorage.getItem("RedirectURI_"+mfaToken)!=undefined)
    {
      callBackUrl=localStorage.getItem("RedirectURI_"+mfaToken)!;
    }
    let clientId='';
    if(localStorage.getItem("ClientId_"+ mfaToken)!=null && localStorage.getItem("ClientId_"+ mfaToken)!=undefined)
    {
        clientId=localStorage.getItem("ClientId_"+ mfaToken)!;
    }
    let clientAppLoginUrl='';
    if(localStorage.getItem("RedirectURI_"+ mfaToken)!=null && localStorage.getItem("RedirectURI_"+ mfaToken)!=undefined)
    {
       clientAppLoginUrl=localStorage.getItem("RedirectURI_"+mfaToken)!;
    }
    let sourceClientId='';
    if(localStorage.getItem("SourceClientId_"+ mfaToken)!=null && localStorage.getItem("SourceClientId_"+ mfaToken)!=undefined)
    {
      sourceClientId=localStorage.getItem("SourceClientId_"+ mfaToken)!
    }

    let loginUrl='';
    if(localStorage.getItem("LoginUrl_"+ mfaToken)!=null && localStorage.getItem("LoginUrl_"+ mfaToken)!=undefined)
    {
      loginUrl=localStorage.getItem("LoginUrl_"+mfaToken)!;
    }

    var obj = {
      mfA_Token:mfaToken,
      password: pwd,
      confirmPassword: confirmPwd,
      client_Id:clientId,
      sourceClient_Id:sourceClientId,
      redirectURI: clientAppLoginUrl
      
    }

      // let reqObj= new ResetPasswordRequestModel();
      let mfaTokenRequestObj= this.getQueryStringValues().then( qsValues => { 
        // var obj = {
        //   mfA_Token:qsValues.MFAToken,
        //   password: decodeURIComponent(pwd),
        //   confirmPassword: decodeURIComponent(confirmPwd),
        //   client_Id:qsValues.ClientId,
        //   sourceClient_Id:qsValues.SourceClientId,
        //   redirectURI: qsValues.CallBackUrl
          
        // }
        this.restPwdSvc.ResetPassword(obj)
          .subscribe({
            next: (resp: unknown) => {
              console.log(resp);
              //$("#divProcessing").hide();
              this.commonUtilSvc.hideProgressBar();
                  if (!(resp instanceof AppError)) {      
          
              const response = resp as unknown as IResetPasswordResponseDetails;
              const result =  response.resultContent;
              if(response==null)
              {
                  this.displayValidationErroMsg("Error occured");
                  
              }
              else
              {
                let fromUrl='';
                if(localStorage.getItem("FromUrl_"+ mfaToken)!=null && localStorage.getItem("FromUrl_"+ mfaToken)!=undefined)
                {
                    fromUrl=localStorage.getItem("FromUrl_"+mfaToken)!;
                }
                if(response.status=='Success' && response.statusCode==200 && response.message!=null 
                && response.message.messageCode=='RCAP-1006')
                {
                  if(fromUrl!=null && fromUrl!=undefined && fromUrl=='RPL'
                    && callBackUrl!=null && callBackUrl!=undefined && callBackUrl.indexOf("support.rpost.com")!=-1)
                    //&& (callBackUrl==null || callBackUrl==undefined || callBackUrl=='')) //Request came from WebAPI Email link and if no callback url,navigate to Resetpassword success screen, incase of desktop outlook
                  {
                    this.router.navigate(['/resetpasswordsuccess']);
                  }
                  else
                  {
                      //redirect to login screen
                    let successMsg=this.translate.instant('ResetPassword.ResetPasswordSuccesMsg');
                    this.displaySuccessMsg(successMsg);
                    setTimeout(function () {
                      window.location.href= loginUrl;
                      }, 3000);

                  }

                }
                else{
                  let errorMsg=this.translate.instant('ResetPassword.ResetPasswordErrorValidationMsg');
                  this.displayValidationErroMsg(errorMsg);
                }
                

              }
              
            }
            else
            {
              
              const response = resp as AppError;
              let errorMsg=response.Message!;
              let errorCode='';
        if(response.originalError!=null && response.originalError!=undefined 
            && response.originalError.error!=null && response.originalError.error!=undefined
            && response.originalError.error.resultContent!=undefined
            && response.originalError.error.resultContent.errors!=null 
            && response.originalError.error.resultContent.errors!=undefined)
            {
               errorCode=response.originalError.error.resultContent.errors[0].code;
               
            }
            else if(response.originalError!=null && response.originalError!=undefined 
              && response.originalError.error!=null && response.originalError.error!=undefined
              && response.originalError.error.message !=null && response.originalError.error.message!=undefined
              )
            {
               errorCode=response.originalError.error.message.messageCode;
            }

            if(errorCode!=null && errorCode!=undefined)
            {
               if(errorCode=='RCAP-1044')
               {
                  
                  errorMsg=this.translate.instant('ResetPassword.ResetPasswordInValidPwdErrorMsg')
                  //Redirect after few seconds
                  //this.redirectToLoginWithErrorReason(errorCode,loginUrl);

               }
               else if(errorCode == 'RCAP-1025') //MFA token is invalid
               {
                  errorMsg=this.translate.instant('EmailAuthentication.MFATokenInValidMsg');
                  //Redirect after few seconds
                  //this.redirectToLoginWithErrorReason(errorMsg,loginUrl);
               }
               
            }
            let sessionExpiryMsg=ErrorReason.RESET_PWD_SESSION_EXPIRED_MSG;
            if(errorMsg == 'MFA token is invalid'|| errorMsg.indexOf('MFA token is invalid')!=-1)
            {
              errorMsg=sessionExpiryMsg;
            }
              this.displayValidationErroMsg(errorMsg);

              this.failedAttempts=this.failedAttempts+1;
              if(this.failedAttempts>3 || errorMsg == sessionExpiryMsg)
              {
                 if(this.failedAttempts>3)
                 {
                    errorMsg= ErrorReason.RESET_PWD_INVALID_ATTEMPTS;
                 }
                 if(errorMsg == sessionExpiryMsg && (loginUrl=='' || loginUrl == null || loginUrl==undefined))
                 {
                    if(callBackUrl!=null && callBackUrl!='' && callBackUrl!=undefined && callBackUrl.indexOf("support.rpost.com")!=-1)
                    {
                        var erroMsgBase64Str=this.commonUtilSvc.ConvertStringToBase64(errorMsg.toString());
                        this.router.navigate(['/resetpassworderror'],{queryParams: {qs: erroMsgBase64Str }});
                    }
                    else
                    {
                      console.log("callbackurl is null/empty");
                    }
                 }
                 else{
                  setTimeout(function () {
                    window.location.href=loginUrl+'?errorReason='+errorMsg;
                    }, 3000);

                 }
                  
              }
              

            }
            }
            ,
            error: (e:any) => console.error(e)
          });

      });
    }
    msgToPostToClient(mfaToken:string)
    {
      let msgToPost='';
      if(localStorage.getItem("MsgToPost_"+mfaToken)!=null 
          && localStorage.getItem("MsgToPost_"+mfaToken)!=undefined)
      {
        msgToPost=localStorage.getItem("MsgToPost_"+mfaToken)!;
      }

      return msgToPost;
    }
    async getQueryStringValues():Promise<QueryStringValuesModel>
      {
        let queryStringValuesObj= new QueryStringValuesModel();
        let qs=this.commonUtilSvc.ConvertBase64ToString(this.qs);
        console.log(qs);
        let emailAddress='';//this.commonUtilSvc.GetParameterByName("?EmailAddress",qs)!;
        let password= '';//this.commonUtilSvc.GetParameterByName("Pwd",qs)!;
        let clientId= this.rcapClientId!;//this.commonUtilSvc.GetParameterByName("ClientId",qs)!;
        let sourceClientId= this.commonUtilSvc.GetParameterByName("?SourceClientId",qs)!;
        let mfaToken=this.commonUtilSvc.GetParameterByName("MFAToken",qs)!;
        let callBackUrl=this.commonUtilSvc.GetParameterByName("CallBackUrl",qs)!;
        let mfaEnabled= this.commonUtilSvc.GetParameterByName("MFAEEnabled",qs)!;
        let mfaEnabledBoolValue=(mfaEnabled=="true");
        let passwordChangeType=parseInt(this.commonUtilSvc.GetParameterByName("MFAEEnabled",qs)!);
        let loginUrl=this.commonUtilSvc.GetParameterByName("LoginUrl",qs)!;
        let lang=this.commonUtilSvc.GetParameterByName("Lang",qs)!;
        
        queryStringValuesObj.EmailAddress=emailAddress;
        queryStringValuesObj.Password=password;
        queryStringValuesObj.ClientId=clientId;
        queryStringValuesObj.SourceClientId=sourceClientId;
        queryStringValuesObj.MFAToken=mfaToken;
        queryStringValuesObj.CallBackUrl=callBackUrl;
        queryStringValuesObj.MFAEEnabled=mfaEnabledBoolValue;
        queryStringValuesObj.PasswordChangeType=passwordChangeType;
        queryStringValuesObj.LoginUrl=loginUrl;
        queryStringValuesObj.Language=lang;
        return queryStringValuesObj;
        
        
      }

      hasTwoUppercaseLetters() { 
        const password = this.resetForm.controls.txtPassword.value;
        this.hasUppercaseFlag = /[A-Z].*[A-Z]/.test(password);
        return this.hasUppercaseFlag;
      }

      hasTwoLowercaseLetters() {
        const password = this.resetForm.controls.txtPassword.value;
        this.hasLowercaseFlag = /[a-z].*[a-z]/.test(password);
        return this.hasLowercaseFlag;
      }
    
      hasTwoNumbers() {
        const password = this.resetForm.controls.txtPassword.value;
        this.hasNumberFlag = /[0-9].*[0-9]/.test(password);
        return this.hasNumberFlag;
      }
    
      hasTwoSpecialChars() {
        const password = this.resetForm.controls.txtPassword.value;
        this.hasSpecialCharFlag = /[#?!@$%^&*].*[#?!@$%^&*]/.test(password);
        return this.hasSpecialCharFlag;
      }
      
      isLengthValid() {
        const password = this.resetForm.controls.txtPassword.value;
        this.isLengthValidFlag = password.length >= 16;
        return this.isLengthValidFlag;
      }

      /* isPasswordValid() {
        return (
          this.hasTwoUppercaseLetters() &&
          this.hasTwoLowercaseLetters() &&
          this.hasTwoNumbers() &&
          this.hasTwoSpecialChars() &&
          this.isLengthValid() 
        );
      } */
  // Begin S1-3262
    isPasswordValid() {
      return (
        this.hasUpperCaseLetters() &&
        this.hasLowerCaseLetters() &&
        this.hasNumbers() &&
        this.hasSpecialChars() &&
        this.isPasswordLengthValid() 
      );
    }

   
  getPasswordPolicySettings()
  {
    let mfaToken='';
    if(localStorage.getItem('MFAToken')!=null && localStorage.getItem('MFAToken')!=undefined)
    {
      mfaToken=localStorage.getItem('MFAToken')!;
    }
    
    var pwdPolicySetting : any ='';
    if(localStorage.getItem("PwdPolicySettingsLs_"+mfaToken)!=null 
          && localStorage.getItem("PwdPolicySettingsLs_"+mfaToken)!=undefined)
    {
      //alert(mfaToken);
        pwdPolicySetting= localStorage.getItem("PwdPolicySettingsLs_"+mfaToken);
    }
    //alert(pwdPolicySetting);
    return pwdPolicySetting;
    
  }
  getPasswordPolicySettingsValues()
  {
    var pwdPolicySetting = this.getPasswordPolicySettings();
    var pwdPolicySettingObj;
    if(pwdPolicySetting!=null && pwdPolicySetting!='' && pwdPolicySetting!='undefined')
    {
        pwdPolicySettingObj= JSON.parse(pwdPolicySetting);
    }
    return pwdPolicySettingObj;
  }
  getPasswordRules()
  {
    
    let atleastUpperCaseLetters:number = 1;
    let atleastLowerCaseLetters:number = 1;
    let atleastNumbers:number = 1;
    let atleastSpecialCharacters:number = 1;
    let minPWdLength:number= 8;
    var pwdPolicySettingObj = this.getPasswordPolicySettingsValues();
	if(pwdPolicySettingObj!=null && pwdPolicySettingObj!=undefined)
    {
     //alert(pwdPolicySettingObj.upperCaseCharCount); 
      atleastUpperCaseLetters=(pwdPolicySettingObj.upperCaseCharCount!=null)?pwdPolicySettingObj.upperCaseCharCount:atleastUpperCaseLetters;
      atleastLowerCaseLetters= (pwdPolicySettingObj.lowerCaseCharCount!=null)? pwdPolicySettingObj.lowerCaseCharCount:atleastLowerCaseLetters;
      atleastNumbers = (pwdPolicySettingObj.numberCharCount!=null)?pwdPolicySettingObj.numberCharCount:atleastNumbers;
      atleastSpecialCharacters = (pwdPolicySettingObj.specialCharCount!=null)?pwdPolicySettingObj.specialCharCount:atleastSpecialCharacters;
      minPWdLength = (pwdPolicySettingObj.minCharLength!=null)?pwdPolicySettingObj.minCharLength:minPWdLength;
    }
   
    this.atleastText= this.translate.instant('ResetPassword.AtleastText'); 
    this.upperCasePwdRuleText= this.translate.instant('ResetPassword.UppercasePwdRuleText');
    this.lowerCasePwdRuleText= this.translate.instant('ResetPassword.LowercasePwdRuleText');
    this.numberPwdRuleText= this.translate.instant('ResetPassword.NumberPwdRuleText');
    this.specialCharPwdRuleText= this.translate.instant('ResetPassword.SpecialCharacterPwdRuleText');
    this.pwdLengthPwdRuleText= this.translate.instant('ResetPassword.PasswordLengthPwdRuleText');
   
    this.upperCaseNumberText = atleastUpperCaseLetters;
    this.lowerCaseNumberText = atleastLowerCaseLetters; 
    this.numberText= atleastNumbers; 
    this.specialCharNumberText = atleastSpecialCharacters;
    this.pwdLengthNumberText= minPWdLength;
  }
  getNumberText(numValue:number)
  {
    
    var numberText: any=''
    switch(numValue)
    {
      case 1:
          numberText= "one";
           break;
      case 2:
        numberText= "two";
          break;
      case 3:
        numberText= "three";
        break;
    }
    
    return numberText;
  } 
  
  hasUpperCaseLetters()
  {
    let atleastUpperCaseLetters:number = this.upperCaseNumberText;
    /* let oneUpperCasePattern: any='/[A-Z]/';
    let twoUpperCasePattern: any ='/[A-Z].*[A-Z]/';
    let threeUpperCasePattern: any ='/[A-Z].*[A-Z].*[A-Z]/'; */
    const password = this.resetForm.controls.txtPassword.value;
    //this.hasUppercaseFlag = /[A-Z].*[A-Z]/.test(password);
    

    switch(atleastUpperCaseLetters)
    {
      case 1:
          this.hasUppercaseFlag = /[A-Z]/.test(password);
           break;
      case 2:
          this.hasUppercaseFlag = /[A-Z].*[A-Z]/.test(password);
          break;
      case 3:
        this.hasUppercaseFlag = /[A-Z].*[A-Z].*[A-Z]/.test(password);
        break;
    }

    return this.hasUppercaseFlag;
  }

  hasLowerCaseLetters() {
    let atleastLowerCaseLetters:number = this.lowerCaseNumberText;
    // let oneLowerCasePattern: any='/[a-z]/';
    // let twoLowerCasePattern: any ='/[a-z].*[a-z]/';
    // let threeLowerCasePattern: any ='/[a-z].*[a-z].*[a-z]/';
    const password = this.resetForm.controls.txtPassword.value;
   // this.hasLowercaseFlag = /[a-z].*[a-z]/.test(password);
   switch(atleastLowerCaseLetters)
    {
      case 1:
          this.hasLowercaseFlag = /[a-z]/.test(password);
           break;
      case 2:
          this.hasLowercaseFlag = /[a-z].*[a-z]/.test(password);
          break;
      case 3:
        this.hasLowercaseFlag = /[a-z].*[a-z].*[a-z]/.test(password);
        break;
    }
    return this.hasLowercaseFlag;
  }
   
  hasNumbers() {
    let atleastNumbers:number = this.numberText;
    // let oneNumberPattern: any='/[0-9]/';
    // let twoNumberPattern: any ='/[0-9].*[0-9]/';
    // let threeNumberPattern: any ='/[0-9].*[0-9].*[0-9]/';
    const password = this.resetForm.controls.txtPassword.value;
    //this.hasNumberFlag = /[0-9].*[0-9]/.test(password);
    switch(atleastNumbers)
    {
      case 1:
          this.hasNumberFlag = /[0-9]/.test(password);
           break;
      case 2:
          this.hasNumberFlag = /[0-9].*[0-9]/.test(password);
          break;
      case 3:
        this.hasNumberFlag = /[0-9].*[0-9].*[0-9]/.test(password);
        break;
    }
    return this.hasNumberFlag;
  }

  hasSpecialChars() {
  
    let atleastSpecialCharacters:number = this.specialCharNumberText;
    // let oneSpecialCharPattern: any='/[#?!@$%^&*]/';
    // let twoSpecialCharPattern: any ='/[#?!@$%^&*].*[#?!@$%^&*]/';
    // let threeSpecialCharPattern: any ='/[#?!@$%^&*].*[#?!@$%^&*].*[#?!@$%^&*]/';
    const password = this.resetForm.controls.txtPassword.value;
    //this.hasSpecialCharFlag = /[#?!@$%^&*].*[#?!@$%^&*]/.test(password);
    switch(atleastSpecialCharacters)
    {
      /* case 1:
          this.hasSpecialCharFlag = /[#?!@$%^&*]/.test(password);
           break;
      case 2:
          this.hasSpecialCharFlag = /[#?!@$%^&*].*[#?!@$%^&*]/.test(password);
          break;
      case 3:
        this.hasSpecialCharFlag = /[#?!@$%^&*].*[#?!@$%^&*].*[#?!@$%^&*]/.test(password);
        break;  */
      //Begin S1-3357
      case 1:
          this.hasSpecialCharFlag = /[[\]()_=#+{}|\\;:'"<,>./?!~`@$%^&*-]/.test(password);
           break;
      case 2:
          this.hasSpecialCharFlag = /[[\]()_=#+{}|\\;:'"<,>./?!~`@$%^&*-].*[[\]()_=#+{}|\\;:'"<,>./?!~`@$%^&*-]/.test(password);
          break;
      case 3:
        this.hasSpecialCharFlag = /[[\]()_=#+{}|\\;:'"<,>./?!~`@$%^&*-].*[[\]()_=#+{}|\\;:'"<,>./?!~`@$%^&*-].*[[\]()_=#+{}|\\;:'"<,>./?!~`@$%^&*-]/.test(password);
        break; 
      // case 1:
      //     this.hasSpecialCharFlag = /[#!@$%^&*()_=+{}|;:'"<,>.-]/.test(password);
      //      break;
      // case 2:
      //     this.hasSpecialCharFlag = /[#!@$%^&*()_=+{}|;:'"<,>.-].*[#!@$%^&*()_=+{}|;:'"<,>.-]/.test(password);
      //     break;
      // case 3:
      //   this.hasSpecialCharFlag = /[#!@$%^&*()_=+{}|;:'"<,>.-].*[#!@$%^&*()_=+{}|;:'"<,>.-].*[#!@$%^&*()_=+{}|;:'"<,>.-]/.test(password);
      //   break;

      //End S1-3357
    }
    return this.hasSpecialCharFlag;
  }

  isPasswordLengthValid() {
    let minPWdLength:number= this.pwdLengthNumberText;
    const password = this.resetForm.controls.txtPassword.value;
    this.isLengthValidFlag = password.length >= minPWdLength;
    return this.isLengthValidFlag;
  }

   //End S1-3262
  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }
  
  toggleConfirmPasswordVisibility() {
    this.showConfirmPassword = !this.showConfirmPassword;
  } 

  validateResetForm(resetForm: any) {

    if (resetForm.controls.txtPassword && (resetForm.controls.txtPassword.value != '' || resetForm.controls.txtPassword.value == ''))
        this.validPassword = true;

    if (resetForm.controls.txtConfirmPassword && (resetForm.controls.txtConfirmPassword.value != '' || resetForm.controls.txtConfirmPassword.value == ''))
        this.validConfirmPassword = true;

    return false;

}

onPasswordBlur(event: any) {

  // if ((this.resetForm.controls.txtConfirmPassword.errors?.pattern || this.resetForm.controls.txtConfirmPassword.errors?.minlength) && event.target.value != "")
  //   this.validPasswordPattern = true;
  // else
  //   this.validPasswordPattern = false;

  if (this.resetForm.controls.txtPassword.value != this.resetForm.controls.txtConfirmPassword.value && this.resetForm.controls.txtConfirmPassword.value != "")
    this.validMatchPassword = true;
  else
    this.validMatchPassword = false;

  this.validPassword = event.value != "" ? false : true;

}

onConfirmPasswordBlur(event: any) {

  // if ((this.resetForm.controls.txtConfirmPassword.errors?.pattern || this.resetForm.controls.txtConfirmPassword.errors?.minlength) && event.target.value != "")
  //   this.validConfirmPatternPassword = true;
  // else
  //   this.validConfirmPatternPassword = false;

  if (this.resetForm.controls.txtPassword.value != "" && this.resetForm.controls.txtPassword.value != this.resetForm.controls.txtConfirmPassword.value)
    this.validMatchPassword = true;
  else
    this.validMatchPassword = false;

  this.validConfirmPassword = event.target.value != "" ? true : false;

}

  resetPassword(form: FormGroup) {

    if (this.resetForm.invalid || !this.isPasswordValid() || this.validMatchPassword) {

        var validForm = this.validateResetForm(this.resetForm);

        if (!validForm)
            return;
    }

    if (form.value.txtPassword != form.value.txtConfirmPassword) {
        this.validMatchPassword = true;
        return;
    } 

    this.invokeResetPasswordAPI(form.value.txtPassword,form.value.txtConfirmPassword);
    //this.InvokeResetPasswordAPI()
    // this.authService.resetPassword(obj.password, obj.confirmPassword, obj.code)
    //     .subscribe(resp => {

           
    //         if(resp instanceof IresetPassword){

    //             var result = resp as IresetPassword;

    //             if (result.Status == "Success") {
    //                 this.isPasswordReset = true;
    //                 // this.openSnackBar('Password reset successful', '');
    //             }
    //         }
    //         else{
    //             if (resp.includes("succeeded")) {
    //                 this.isPasswordReset = true;
    //                 // this.openSnackBar('Password reset successful', '');
    //             }
    //         }

    //         this.buildForm();

    //     });

}

sanitize(url:string){
  return this.sanitizer.bypassSecurityTrustUrl(url);
}

getMaskedEmailAddress_V1(emailAddress:string):string
{
  var maskedEmailAddress=emailAddress;
  var loopCounter=0;
  let lastPosition=emailAddress.indexOf("@");
  let posValueAfterAtTheRateSymbol=lastPosition+1;
  for(var i=0; i<emailAddress.length; i++)
  {
    loopCounter=loopCounter+1;

    if(loopCounter<lastPosition)//before @ symbol
    {
      let difference=Math.abs(lastPosition-loopCounter);
      if(difference<=3)
      {
        maskedEmailAddress=replaceAtPosition(maskedEmailAddress,loopCounter);
        
      }
    }
    if(loopCounter>lastPosition)// after @ symbol
    {
      let difference=Math.abs(loopCounter-lastPosition);
      if(difference<=3)
      {
        maskedEmailAddress=replaceAtPosition(maskedEmailAddress,loopCounter);
        
      }
    }
    
    
    
    
     
  }
  return maskedEmailAddress;
}

// displaySuccessMsg(mesg:string)
// {
//   $('#parSuccessMsg').text(mesg);
//   $('#divSuccessMsg').css('display','block');

// }


}

function replaceAtPosition(originalString: string,index: any) {
  //var str = 'Hello World';
  return  setCharAt(originalString,index,'x');
  //alert(str);
}

function setCharAt(str: string,index: number,chr: string) {
  if(index > str.length-1) return str;
  return str.substring(0,index) + chr + str.substring(index+1);
}
